* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
 }
 
 
 .carousel {
   position: relative;
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 1200px;
   height: 400px;
 }
 
 .arrow {
   position: absolute;
   width: 2rem;
   height: 2rem;
   color: black;
   filter: drop-shadow(0px 0px 5px black);
   z-index: 2;
   top: 50%; /* This positions the top edge of the button halfway down the parent. */
   transform: translateY(-50%); /* This moves the button back up by half its own height. */
 }
 
 .left-arrow {
   left: -5%;
 }
 
 .right-arrow {
   right: -5%;
 }
 
 .carousel-content {
   position: relative;
   width: 100%;
   height: auto;
   overflow: hidden;
   line-height: 1;
   padding-bottom: 4rem;
 }

 @media screen and (max-width: 768px) {
  .carousel {
    width: 100%;
    height: 300px;
  }

  .slide-image {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .carousel-content {
    font-size: 0.8rem;
  }
}

 
 .carousel img {
   height: 400px;
   width: 1000px;
   vertical-align: bottom;
 }
 
 /* Optional: Add some space between the carousel and the text */
 .carousel + p {
   margin-left: 20px;
 }

 
 
 .arrow:hover {
   cursor: pointer;
 }
 
 
 .indicators {
   display: flex;
   position: absolute;
   bottom: 1rem;
   left: 50%;
   transform: translateX(-50%);
   z-index: 2; /* Add this line */
 }

 .indicator {
   background-color: white;
   height: 1rem; /* Increase from 0.5rem */
   width: 1rem; /* Increase from 0.5rem */
   border-radius: 100%;
   border: none;
   outline: none;
   box-shadow: 0px 0px 5px #555;
   margin: 0 0.2rem;
   cursor: pointer;
 }
 
 
 
 
 .indicator-inactive {
   background-color: #555;
 }
 
 .slide-container {
   display: flex; /* Add this to arrange slides in a row */
   transition: transform 0.3s ease; /* Add smooth transition for sliding effect */
 }

 .slide-text {
   margin-left: 5px;
   padding-left: 3px;
}
.slide-text p{
  white-space: pre-line;
}

.carousel-header {
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 100px;
}

.carousel-header h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}



 
 .slide {
   border-radius: 0.5rem;
   box-shadow: 0px 0px 7px #666;
   flex: 0 0 100%; /* Make each slide occupy the full width of the container */
   display: flex; /* Added to display image and text side by side */
 }
 
 .slide-hidden {
   display: none;
 }
 
 /* Added class for visible slide */
 .slide-visible {
   transform: translateX(0);
 }
 
 
 
 @media screen and (min-width: 48rem) {
    .carousel {
        display: flex;
        align-items: center;
    }
 }
 
 
 .carousel-content span {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 1rem;
    color: #717171;
 }
 
 
 .carousel-content h1 {
    text-transform: capitalize;
    letter-spacing: 0.8px;
    font-size: 4rem;
    line-height: 1.1;
    background-color: #00a4aa;
    background-image: linear-gradient(45deg, #00a4aa, #000);
    background-size: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
 }
 
 
 .carousel-content hr {
    display: block;
    background: #00a4aa;
    height: 0.25rem;
    width: 6.25rem;
    border: none;
    margin: 1.125rem 0 1.875rem 0;
 }
 
 
 .carousel-content p {
    line-height: 1;
 }
 
 
 .slider-btn {
    display: inline-block;
    color: #717171;
    background: #fff;
    padding: 0.625rem 1.875rem;
    margin-top: 2.125rem;
    text-decoration: none;
    text-transform: uppercase;
    border: 2px solid #c2c2c2;
    border-radius: 3.125rem;
    transition: 0.3s ease-in-out;
 }
 
 
 .slider-btn:hover {
    border: 0.125rem solid #00a4aa;
    color: #00a4aa;
 }
 
 
 
 
 .slide-image {
    height: auto;
    max-height: 450px;
    width: auto;
    max-width: 1000px;
    display: block;
    transition: transform 0.5s ease;
 }

 /* .slide-image:hover {
  transform: scale(1.1);
 } */
 
/* Parent container for the slider and description */
.slider-container {
   width: auto;
   display: grid;
   grid-template-columns: 1fr 1fr; /* Divide the container into two equal columns */
   gap: 20px; /* Add some gap between the columns */
 }
 
 /* Style for the Swiper container */
 .swiper-container {
   grid-column: 1; /* Position the Swiper in the first column */
 }
 
 /* Style for the description container */
 .description-container {
   grid-column: 2; /* Position the description in the second column */
 }
 
 /* Add this CSS to your existing styles or create a new CSS file */
/* Update the CSS to align the service items properly */

#services .container {
   display: flex;
   flex-wrap: wrap;
 }
 
 #services .row {
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
 }
 
 #services .col-md-4 {
   flex: 0 0 33.33%;
   max-width: 33.33%;
   padding: 15px;
 }
 
 /* Make sure the height of each service item is consistent */
 #services .service-item {
   display: flex;
   flex-direction: column;
   height: 100%;
 }
 
 #services .service-desc {
   flex: 1; /* Allow the description to fill the remaining space */
 }
 